import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth.service';
import { ModalService } from 'src/app/modal.service';


@Component({
  selector: 'app-addupdateusermodal',
  templateUrl: './addupdateusermodal.component.html',
  styleUrls: ['./addupdateusermodal.component.css']
})
export class AddupdateusermodalComponent implements OnInit {
  @Input() selectedVoter: any; // Declare selectedVoter as an Input property
  @Output() closeModalEvent = new EventEmitter<void>(); // Renamed event emitter
  senderName: string = '';
  amountPaid: number = 0;
  paymentStatus: string;
  userDetails : any;
  showModal: boolean = false;
  editMode: boolean = false;


  constructor(private http: HttpClient,private authService:AuthService, private modalService: ModalService,private toastr : ToastrService ) { 
    this.userDetails = this.authService.getUserDetails()
    this.senderName = this.userDetails.full_name;
    this.amountPaid = 2000;
  }
  ngOnInit(): void {
    // throw new Error('Method not implemented.');
  }
  closeModal() {
    console.log("clicked Here");
    this.editMode = false;
    this.showModal = false; // Emitting the closeModalEvent
  }
  editedUserDetails : any;
  openModal(voter: any) {
    this.selectedVoter = voter;
    this.userDetails = { ...voter }; // Make a copy to prevent directly modifying the original object
    console.log("data",voter);
    this.editMode = true;
    this.showModal = true; // Emitting the closeModalEvent
  }

  submitChanges() {
    // Combine original and edited details
    const updatedFields = { ...this.userDetails, ...this.editedUserDetails };
    
    // Extract required fields for API request
    const { username, email, password_hash, is_admin } = updatedFields;
    const fullName = updatedFields.full_name;
    const dateOfBirth = updatedFields.date_of_birth;
    const gender = updatedFields.gender;

    const requestBody = {
      username,
      email,
      password_hash,
      fullName,
      dateOfBirth,
      gender,
      isAdmin: is_admin
    };

    this.http.post<any>('https://sa3gut5n3e.execute-api.us-east-1.amazonaws.com/dev/addupdateuser', requestBody)
      .subscribe(
        response => {
          if (response.message == "User data updated successfully") {
            this.toastr.success('User details updated successfully');
            this.editMode = false;
            this.closeModal()
            // location.reload();
          } else {
            this.toastr.error('Failed to update');
          }
        },
        error => {
          console.error('Error occurred while updating user details:', error);
          this.toastr.error('Failed to update user details');
        }
      );
  }
}
