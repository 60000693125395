import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private closeModalSubject = new Subject<void>();
  public closeModal$ = this.closeModalSubject.asObservable();

  constructor() {}

  closeModal() {
    this.closeModalSubject.next(); // Emit the close event
  }
}
