import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isAuthenticated: boolean = false;
  private userDetailsKey = 'userDetails';
  private userDetails: any;

  constructor() {
    // Check if user details exist in local storage on initialization
    const userDetailsString = localStorage.getItem(this.userDetailsKey);
    if (userDetailsString) {
      this.userDetails = JSON.parse(userDetailsString);
      this.isAuthenticated = true; // User is considered authenticated if userDetails exist
    }
  }

  // Simulated login method
  login() {
    this.isAuthenticated = true;
  }

  // Simulated logout method
  logout() {
    this.isAuthenticated = false;
    // Clear user details from local storage on logout
    localStorage.removeItem(this.userDetailsKey);
    this.userDetails = null;
  }

  // Check if the user is authenticated
  isAuthenticatedUser(): boolean {
    return this.isAuthenticated;
  }

  setUserDetails(userDetails: any) {
    this.userDetails = userDetails;
    // Store user details in local storage
    localStorage.setItem(this.userDetailsKey, JSON.stringify(userDetails));
    this.isAuthenticated = true;
  }

  getUserDetails(): any {
    return this.userDetails;
  }

  clearUserDetails() {
    this.userDetails = null;
    // Clear user details from local storage
    localStorage.removeItem(this.userDetailsKey);
    this.isAuthenticated = false;
  }
}
