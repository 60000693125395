import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-header-stats",
  templateUrl: "./header-stats.component.html",
})
export class HeaderStatsComponent implements OnInit {
  totalMoneyPaid: string = "0.00";
  totalMembersPaid: number = 0;
  totalMembersPending: number = 0;
  totalMembersPaidPercentage: any;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData() {
    this.http.post<any>("https://sa3gut5n3e.execute-api.us-east-1.amazonaws.com/dev/reports", {})
      .subscribe((data) => {
        this.totalMoneyPaid = data.total_money_paid || "0.00";
        this.totalMembersPaid = data.total_members_paid || 0;
        this.totalMembersPending = data.total_members_pending || 0;
        
        // Calculate percentage of total members paid
        const totalMembers = this.totalMembersPaid + this.totalMembersPending;
        console.log("totalMembers",totalMembers);

        this.totalMembersPaidPercentage = (this.totalMembersPaid / totalMembers).toFixed(8);
        console.log("totalMembersPaidPercentage",this.totalMembersPaidPercentage);
      });
  }
}
