import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { ModalService } from 'src/app/modal.service';
import { VoterDetailsModalComponent } from '../voter-details-modal/voter-details-modal.component';

@Component({
  selector: 'app-voterdetails',
  templateUrl: './voterdetails.component.html',
  styleUrls: ['./voterdetails.component.css']
})
export class VoterdetailsComponent implements OnInit {
  tableData: any[] = [];
  filteredData: any[] = [];
  color: string = 'light';
  selectedVoter: any;
  showModal: boolean;
  @ViewChild(VoterDetailsModalComponent) voterDetailsModal: VoterDetailsModalComponent;

  constructor(private http: HttpClient,private modalService: ModalService // Inject the ModalService
  ) {}

  ngOnInit() {
    this.fetchData();
  }

  fetchData() {
    this.http
      .post<any>('https://sa3gut5n3e.execute-api.us-east-1.amazonaws.com/dev/getallvoters', { page: 1, limit: 1000 })
      .subscribe(
        (data) => {
          this.tableData = data.rows;
          this.filteredData = data.rows;
        },
        (error) => {
          console.error('Error fetching data:', error);
        }
      );
  }

  applyFilter(filterValue: string) {
    this.filteredData = this.tableData.filter((row) =>
      row.Voter_id.toLowerCase().includes(filterValue.toLowerCase())
    );
  }



  openModal(voter: any) {
    this.voterDetailsModal.openModal(voter);
  }

  closeModal() {
    this.voterDetailsModal.closeModal();
  }
}