
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})

export class LoginComponent {
  email: string = '';
  password: string = '';

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService
    // private cookieService: CookieService
  ) {}

  onSubmit() {
    console.log(this.email);
    console.log(this.password);

    // Request body
    const requestBody = {
      username: this.email,
      password: this.password
    };

    // Make API call to login endpoint
    this.http.post<any>('https://sa3gut5n3e.execute-api.us-east-1.amazonaws.com/dev/login', requestBody)
      .subscribe(
        (response) => {
          console.log('Login response:', JSON.parse(response.body));
           let res = JSON.parse(response.body);
          // Store authentication status and message in cookies
          // this.cookieService.set('isAuthenticated', response.success.toString());
          // this.cookieService.set('loginMessage', response.message || '');

          // Redirect to dashboard if login successful
          if (res.success) {
            this.toastr.success("Logged in Succesfully")
            this.authService.login()
            // localStorage.setItem('user',res.userDetails);
            this.authService.setUserDetails(res.userDetails);
            this.router.navigate(['admin/dashboard']);
          }
          else{
            this.toastr.error(res.message);
          }
        },
        (error) => {
          console.error('Login error:', error);

          // Handle error response
          const errorMessage = error?.error?.message || 'An error occurred during login';
          alert(errorMessage); // You can display a more user-friendly message if needed
        }
      );
  }
}
