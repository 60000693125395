// import { Component, OnInit, Input } from "@angular/core";

// @Component({
//   selector: "app-card-table",
//   templateUrl: "./card-table.component.html",
// })
// export class CardTableComponent implements OnInit {
//   @Input()
//   get color(): string {
//     return this._color;
//   }
//   set color(color: string) {
//     this._color = color !== "light" && color !== "dark" ? "light" : color;
//   }
//   private _color = "light";

//   constructor() {}

//   ngOnInit(): void {}
// }

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: "app-card-table",
  templateUrl: "./card-table.component.html",
})
export class CardTableComponent implements OnInit {
  tableData: any[] = [];
  color: string = 'light';

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.fetchData();
  }

  fetchData() {
    this.http
      .post<any>(
        'https://sa3gut5n3e.execute-api.us-east-1.amazonaws.com/dev/getmoneypaiddetails',
        {}
      )
      .subscribe(
        (data) => {
          this.tableData = data.rows;
        },
        (error) => {
          console.error('Error fetching data:', error);
        }
      );
  }
}

