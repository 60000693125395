import { Component, OnInit, AfterViewInit } from "@angular/core";
import Chart from "chart.js";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-card-line-chart",
  templateUrl: "./card-line-chart.component.html",
})
export class CardLineChartComponent implements OnInit, AfterViewInit {
  constructor(private http: HttpClient) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.fetchDataAndRenderChart();
  }

  fetchDataAndRenderChart() {
    this.http
      .post<any>(
        "https://sa3gut5n3e.execute-api.us-east-1.amazonaws.com/dev/reports",
        {}
      )
      .subscribe((data) => {
        this.renderChart(data);
      });
  }

  renderChart(data: any) {
    var config = {
      type: "bar",
      data: {
        labels: ["Total Money Paid", "Total Members Paid", "Total Members Pending"],
        datasets: [
          {
            label: "Value",
            backgroundColor: ["#4c51bf", "#ffa600", "#ff6f61"],
            data: [
              parseFloat(data.total_money_paid),
              data.total_members_paid,
              data.total_members_pending
            ]
          }
        ]
      },
      options: {
        responsive: true,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            ticks: {
              fontColor: "rgba(255,255,255,.7)",
              fontStyle: "bold"
            }
          }],
          yAxes: [{
            ticks: {
              fontColor: "rgba(255,255,255,.7)",
              fontStyle: "bold",
              beginAtZero: true
            }
          }]
        }
      }
    };

    let ctx: any = document.getElementById("bar-chart") as HTMLCanvasElement;
    ctx = ctx.getContext("2d");
    new Chart(ctx, config);
  }
}
