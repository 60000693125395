import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AddupdateusermodalComponent } from '../addupdateusermodal/addupdateusermodal.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  tableData: any[] = [];
  filteredData: any[] = [];
  color: string = 'light';
  selectedVoter: any;
  showModal: boolean;
  @ViewChild(AddupdateusermodalComponent) addupdateuserModal: AddupdateusermodalComponent;

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.fetchData();
  }

  fetchData() {
    this.http
      .post<any>(' https://sa3gut5n3e.execute-api.us-east-1.amazonaws.com/dev/getallusers', {})
      .subscribe(
        (data) => {
          this.tableData = data.rows;
          this.filteredData = data.rows;
        },
        (error) => {
          console.error('Error fetching data:', error);
        }
      );
  }

  applyFilter(filterValue: string) {
    this.filteredData = this.tableData.filter((row) =>
      row.Voter_id.toLowerCase().includes(filterValue.toLowerCase())
    );
  }

  openModal(voter: any) {
    this.addupdateuserModal.openModal(voter);
  }

  closeModal() {
    this.addupdateuserModal.closeModal();
    this.fetchData();
  }
}