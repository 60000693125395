import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/auth.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  collapseShow = "hidden";
  userDetails : any;
  constructor(private authService:AuthService) {
   this.userDetails = this.authService.getUserDetails();
  }

  ngOnInit() {}
  toggleCollapseShow(classes) {
    this.collapseShow = classes;
    
  }
}
