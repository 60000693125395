import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth.service';
import { ModalService } from 'src/app/modal.service';

@Component({
  selector: 'app-voter-details-modal',
  templateUrl: './voter-details-modal.component.html',
  styleUrls: ['./voter-details-modal.component.css']
})
export class VoterDetailsModalComponent {
  @Input() selectedVoter: any; // Declare selectedVoter as an Input property
  @Output() closeModalEvent = new EventEmitter<void>(); // Renamed event emitter
  senderName: string = '';
  amountPaid: number = 0;
  paymentStatus: string;
  userDetails : any;
  showModal: boolean = false;



  constructor(private http: HttpClient,private authService:AuthService, private modalService: ModalService,private toastr : ToastrService ) { 
    this.userDetails = this.authService.getUserDetails()
    this.senderName = this.userDetails.full_name;
    this.amountPaid = 2000;
  }
  closeModal() {
    console.log("clicked Here")
    this.showModal = false; // Emitting the closeModalEvent
  }

  openModal(voter: any) {
    this.selectedVoter = voter;
    this.showModal = true; // Emitting the closeModalEvent
  }

  submitPayment() {
    const paymentData = {
      voterId: this.selectedVoter.Voter_id,
      amount: this.amountPaid,
      senderName: this.senderName
    };

    this.http.post<any>('https://sa3gut5n3e.execute-api.us-east-1.amazonaws.com/dev/updatemoney', paymentData)
      .subscribe(
        response => {
          // Handle response to determine payment status
          if (response.message == 'Transaction updated successfully') {
            this.toastr.success("Payment Successfully Done");
            this.closeModal();
          } else {
            this.toastr.error("Payment failed");
          }
        },
        error => {
          console.error('Error occurred while processing payment:', error);
          this.paymentStatus = 'Payment failed';
        }
      );
  }
}
