import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { VoterDetailsModalComponent } from '../voter-details-modal/voter-details-modal.component';

@Component({
  selector: 'app-sendmoney',
  templateUrl: './sendmoney.component.html',
  styleUrls: ['./sendmoney.component.css']
})
export class SendmoneyComponent implements OnInit {
  searchQuery: string = '';
  voterData: any[] = [];
  selectedVoter: any;
  @ViewChild(VoterDetailsModalComponent) voterDetailsModal: VoterDetailsModalComponent;

  constructor(private http: HttpClient) { }
  ngOnInit(): void {
  }
  searchVoters() {
    if (this.searchQuery.trim() !== '') {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      // const body = { q: this.searchQuery };
      this.http.post<any>(`https://sa3gut5n3e.execute-api.us-east-1.amazonaws.com/dev/searchvoters?q=${this.searchQuery}`, { headers })
        .subscribe(response => {
          this.voterData = response.rows;
        });
    }
  }
  // openPaymentPopup(voter: any) {
  //   this.selectedVoter = voter;
  //   this.showModal = true;
  //   // Code to open payment popup
  // }

  openModal(voter: any) {
    if (this.voterDetailsModal) {
      this.voterDetailsModal.openModal(voter);
    }
  }

  closeModal() {
    if (this.voterDetailsModal) {
      this.voterDetailsModal.closeModal();
    }
  }
}