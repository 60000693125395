import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2pdf from 'html2pdf.js';



@Component({
  selector: 'app-boothwise',
  templateUrl: './boothwise.component.html',
  styleUrls: ['./boothwise.component.css']
})
export class BoothwiseComponent implements OnInit {
  selectedType: string = ''; // to store selected report type
  reportData: any[] = []; // to store fetched report data
  apiUrlBoothwise: string = 'https://sa3gut5n3e.execute-api.us-east-1.amazonaws.com/dev/getallbothwise';
  apiUrlSenderName: string = 'https://sa3gut5n3e.execute-api.us-east-1.amazonaws.com/dev/getsendername';

  constructor(private http: HttpClient) {}
  ngOnInit(): void {
  }

  loadReports() {
    if (this.selectedType === 'boothwise') {
      this.http.post(this.apiUrlBoothwise, {}).subscribe((data: any) => {
        this.reportData = data.rows;
        console.log('data',this.reportData);
      });
    } else if (this.selectedType === 'sendername') {
      this.http.post(this.apiUrlSenderName, {}).subscribe((data: any) => {
        this.reportData = data.rows;
        console.log('data',this.reportData);

      });
    }
  }

  exportToExcel(): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.reportData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'report.xlsx');
  }
  
  exportToPDF(): void {
    const element = document.getElementById('reportData'); // Assuming 'reportData' is the ID of the HTML element containing your report data
    html2pdf().from(element).save('report.pdf');
  }
  
  
  
  
}