// import { Component, OnInit } from "@angular/core";

// @Component({
//   selector: "app-card-page-visits",
//   templateUrl: "./card-page-visits.component.html",
// })
// export class CardPageVisitsComponent implements OnInit {
//   constructor() {}

//   ngOnInit(): void {}
// }

import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-card-page-visits",
  templateUrl: "./card-page-visits.component.html",
})
export class CardPageVisitsComponent implements OnInit {
  tableData: any[] = [];

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.fetchData();
  }

  fetchData() {
    this.http
      .post<any>(
        "https://sa3gut5n3e.execute-api.us-east-1.amazonaws.com/dev/getmoneypaiddetails",
        {} // pass any data if required by the API
      )
      .subscribe(
        (data) => {
          this.tableData = data.rows;
        },
        (error) => {
          console.error("Error fetching data:", error);
        }
      );
  }
}
